<template>
  <div class="hello">
      <div class="screenUse">
        <Nav-bar style="width: 100%;"/>
        <vs-row>
          <vs-col vs-w="2.5">
            <Side-bar style="width: 100%;"/>
          </vs-col>
          <vs-col vs-w="9.5" style="overflow:scroll; height: calc(100vh - 48px);">
            <div style="position: fixed; width: calc(79% - 13px); z-index: 1000;">
              <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
              <div style="width: 800px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> Administration /</span> Frais Finance
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                      <el-input style="width: 100%;" v-model="anneeSelect" placeholder="Prix">
                      </el-input>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                      <vs-select
                        placeholder="Mois"
                        class="selectExample"
                        label-placeholder="Autocomplete"
                        autocomplete
                        v-model="moisSelect">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in donneesMois" />
                      </vs-select>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                      <el-button icon="el-icon-search" circle @click="searchDate()"></el-button>
                    </vs-col>
                  </vs-row>
              </div>
              <span style="float: right; margin-right: 150px;" >
                    <vs-button color="#C32D39"  icon="paid" style="padding: 5px; position: absolute; width: 150px;" @click="activePrompt2=true">Nouveau Paiement</vs-button>
                </span>
                <span style="float: right; margin-right: 500px;">
                    <vs-button color="#C32D39"  icon="print" style="padding: 5px; position: absolute; width: 150px;" @click="generateReport">Imprimer</vs-button>
                </span>
              </vs-card>
            </div>
            
            <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">

              <vs-divider position="left"><img style="margin-top: 5px;" src="../assets/sogecit.png" width="150"></vs-divider>
              <vs-row>

                  <vs-col vs-w="3" style="padding-right: 10px;">
                    <el-card class="box-card" style="padding: -15px;  background-color: #c1c5f014;">
                      <vs-icon icon="payments" size="small" bg="#ff000073" style="color: #970303; font-size: 20px;" round></vs-icon> <span class="titre-zone">TOTAL SALAIRE</span>
                          <vs-row class="space-zone">
                            <vs-col vs-w="12" style="display: grid;">
                              <vs-row class="space-zone">
                                
                                <vs-col vs-w="12" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #ef665b;" class="prix-zone">{{ totalSalaires | devise('XOF') }}</span></span>
                                </vs-col>
                              </vs-row>
                             
                             
                            </vs-col>
                            
                          </vs-row>
                    </el-card>
                  </vs-col>
                  
                </vs-row>

                <vs-divider position="left">LISTE DES DEPENSES DU <b>(03/04/2023)</b></vs-divider>
                
                <el-card class="box-card" style="padding: -15px; border-radius: 20px;">
                  <vs-table search max-items="20" pagination  stripe :data="donnees" style="font-size: 16px">
                    <template slot="header">
                      <h3>
                        Listes des dépenses
                      </h3>
                    </template>
                    <template slot="thead">
                      <vs-th>
                        Année
                      </vs-th>
                      <vs-th>
                        Mois
                      </vs-th>
                      <vs-th>
                        Salarié
                      </vs-th>
                      <vs-th>
                        Montant
                      </vs-th>
                      <vs-th>
                        Observation
                      </vs-th>
                      <vs-th>
                        Action
                      </vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td :data="data[indextr].annee">
                        <span style="color: blue; font-size: 16px">{{data[indextr].annee}}</span>
                        </vs-td>

                        <vs-td style="font-size: 16px" :data="data[indextr].mois">
                          {{data[indextr].mois}}
                        </vs-td>

                        <vs-td
                          label="Vehicule" style="font-size: 16px">
                              {{ data[indextr].nom }}
                        </vs-td>

                        <vs-td style="font-size: 16px" :data="data[indextr].id">
                          {{formatMoney(data[indextr].montant)}}
                        </vs-td>

                        <vs-td style="font-size: 16px" :data="data[indextr].id">
                          {{data[indextr].observation}}
                        </vs-td>
                        <vs-td style="font-size: 16px" :data="data[indextr].id">
                          <el-button type="primary" icon="el-icon-edit" circle @click="modifBon(data[indextr])"></el-button>
                          <el-button type="danger" icon="el-icon-delete" circle @click="supBon(data[indextr].id_salarie)"></el-button>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
               
                </el-card>

              
              <vs-divider/>
              
            </div>
          </vs-col>
        </vs-row>

        <vs-popup classContent="popup-example"  title="Paiement Salaire" :active.sync="activePrompt2">
          <div>
            <vs-row>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Année</label>
                <el-input v-model="form.annee" placeholder="Date du bon">
                </el-input>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Mois</label>
                <vs-select
                  style="width: 100%;"
                  placeholder="Mois"
                  class="selectExample"
                  label-placeholder="Autocomplete"
                  autocomplete
                  v-model="form.mois"
                  >
                  <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in donneesMois" />
                </vs-select>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Nom & prenom</label>
                <vs-select
                  style="width: 100%;"
                  placeholder="Nom"
                  class="selectExample"
                  label-placeholder="Autocomplete"
                  autocomplete
                  v-model="form.nom"
                  >
                  <vs-select-item :key="index" :value="item.nom_prenom" :text="item.nom_prenom" v-for="item,index in donneesChauf" />
                  <vs-select-item value="DERO OUSMANE" text="DERO OUSMANE"/>
                  <vs-select-item value="CHEICK OUMAR" text="CHEICK OUMAR"/>
                  <vs-select-item value="KADER BASOLE" text="KADER BASOLE"/>
                  <vs-select-item value="MOUSSA CISSE" text="MOUSSA CISSE"/>
                </vs-select>
              </vs-col>
            </vs-row>
            <vs-row>
              
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Montant</label>
                <el-input v-model="form.montant" placeholder="Montant">
                </el-input>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="8">
                <label>Observation</label>
                <el-input  type="textarea" v-model="form.observation" placeholder="Observation">
                </el-input>
              </vs-col>
            </vs-row>
          
            
            
          </div>
         
          
          <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmit()" color="primary" type="filled">Enregistrer le salaire</vs-button></center>
         
        </vs-popup>

        <vs-popup classContent="popup-example"  title="Modification Salaire" :active.sync="popupActivo3">
          <div>
            <vs-row>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Année</label>
                <el-input v-model="form.annee" placeholder="Date du bon">
                </el-input>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Mois</label>
                <vs-select
                  style="width: 100%;"
                  placeholder="Mois"
                  class="selectExample"
                  label-placeholder="Autocomplete"
                  autocomplete
                  v-model="form.mois"
                  >
                  <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in donneesMois" />
                </vs-select>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Nom & prenom</label>
                <vs-select
                  style="width: 100%;"
                  placeholder="Nom"
                  class="selectExample"
                  label-placeholder="Autocomplete"
                  autocomplete
                  v-model="form.nom"
                  >
                  <vs-select-item :key="index" :value="item.nom_prenom" :text="item.nom_prenom" v-for="item,index in donneesChauf" />
                  <vs-select-item value="DERO OUSMANE" text="DERO OUSMANE"/>
                  <vs-select-item value="CHEICK OUMAR" text="CHEICK OUMAR"/>
                  <vs-select-item value="KADER BASOLE" text="KADER BASOLE"/>
                  <vs-select-item value="MOUSSA CISSE" text="MOUSSA CISSE"/>
                </vs-select>
              </vs-col>
            </vs-row>
            <vs-row>
              
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Montant</label>
                <el-input v-model="form.montant" placeholder="Montant">
                </el-input>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="8">
                <label>Observation</label>
                <el-input  type="textarea" v-model="form.observation" placeholder="Observation">
                </el-input>
              </vs-col>
            </vs-row>
          
            
            
          </div>
         
          
          <center><vs-button style="margin: 20px; width: 300px;" @click="onUpdate()" color="primary" type="filled">Modifier le salaire</vs-button></center>
         
        </vs-popup>

        <vs-popup classContent="popup-example"  title="Nouveau dossier" :active.sync="popupActivoCaisse">
          <div>
            <vs-row>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Date de la dépense</label>
                <el-input  type="date" v-model="caisseData.date" placeholder="Date de Réception">
                </el-input>
              </vs-col>
              
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4" >
                <label>Somme</label>
                <el-input v-model="caisseData.somme" placeholder="Somme">
                </el-input>
                
              </vs-col>
              
            </vs-row>
            
          </div>
         
          <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmitCaisse()" color="primary" type="filled">Ajouter à la caisse</vs-button></center>
          
        </vs-popup>

        <vs-popup classContent="popup-example"  title="Bordereau de livraison" :active.sync="activePrompt9">
          
          <center style="width: 21cm;">
            
            <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1000px"

            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
              <div>
                <div style="width: 29.5cm; margin-left: .5cm; margin-top: 20px;" id="html-to-pdf">
                  <div style="width: 100%; padding: 10px">
                    <div style="float: left; display: block;">
                      <img src="../assets/sogecit.png" width="185">
                    </div>
                    <div style="float: left; padding: 12px;">
                      <span style="font-weight: bold; font-size: 19px; font-family: 'Nunito Sans', sans-serif;">LISTE DES DEPENSES - <span><b> </b></span><span style="color: red; font-size: 18px">( total dépenses - <b>3434 CFA</b> )</span></span>
                    </div>
                  </div>
                .
                  <h2></h2>
                  <vs-divider/>
                  <vs-row style="width: 29cm;">


                    <!--
                                      <vs-col vs-w="3" style="padding-right: 10px;">
                                          <el-card class="box-card" style="padding: -15px;  background-color: #c1c5f014;">
                                            <vs-icon icon="payments" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">CAISSE -  {{ donneesCaisse[0].total_caisse }}</span>
                                                <vs-row class="space-zone">
                                                  <vs-col vs-w="12" style="display: grid;">
                                                    <vs-row class="space-zone">
                                                      
                                                      <vs-col vs-w="6.5" style="display: grid;">
                                                        <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone">{{donneesCaisse[0].total_caisse - totalPrix[0].total_depense}} CFA</span></span>
                                                      </vs-col>
                                                    </vs-row>
                                                   
                                                   
                                                  </vs-col>
                                                  
                                                </vs-row>
                                          </el-card>
                                        </vs-col>
                    -->
                                        <vs-col vs-w="4" style="padding-right: 10px;">
                                          <el-card class="box-card" style="padding: -15px;  background-color: #c1c5f014;">
                                            <vs-icon icon="payments" size="small" bg="#ff000073" style="color: #970303; font-size: 20px;" round></vs-icon> <span class="titre-zone">TOTAL SALAIRE</span>
                                                <vs-row class="space-zone">
                                                  <vs-col vs-w="12" style="display: grid;">
                                                    <vs-row class="space-zone">
                                                      
                                                      <vs-col vs-w="12" style="display: grid;">
                                                        <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #ef665b;" class="prix-zone">{{ totalSalaires }} CFA</span></span>
                                                      </vs-col>
                                                    </vs-row>
                                                   
                                                   
                                                  </vs-col>
                                                  
                                                </vs-row>
                                          </el-card>
                                        </vs-col>
                                        
                                      </vs-row>
                  <vs-divider/>
                  <el-table
                        ref="multipleTable"
                        :data="donnees"
                        stripe
                        style="font-size: 12px; margin-left: 1px; font-family: Helvetica; width: 29.5cm"
                        @selection-change="handleSelectionChange">
                        <el-table-column
                          type="selection"
                          width="55">
                          <img style="margin-top: 5px;" src="../assets/camionbenne.png" width="20">
                        </el-table-column>
                        <el-table-column
                            width="110"
                            property="name"
                            label="Date">
                            <template slot-scope="scope">
                            <el-link type="primary" style="font-size:12px"><i class="el-icon-document"></i> {{ scope.row.date_dep }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column
                          width="160"
                          label="Type Dépenses">
                          <template slot-scope="scope">
                              {{ scope.row.type_dep }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          width="160"
                          label="Vehicule">
                          <template slot-scope="scope">
                              {{ scope.row.matricule }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          width="310"
                          label="Motif">
                          <template slot-scope="scope">
                              {{ scope.row.motif }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          width="160"
                          label="Somme">
                          <template slot-scope="scope">
                            <b>{{ formatMoney(scope.row.somme) }}</b>
                          </template>
                        </el-table-column>
                        
                      </el-table>
                </div>
              </div>
                
            </section>
        </vue-html2pdf>
           
                  
          </center>
                
         
        </vs-popup>
      </div>
     
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from "html2pdf.js";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import axios from 'axios';
import SideBar from '@/components/Sidebar.vue'
import NavBar from '@/components/Navbar.vue'
export default {
  name: 'HelloWorld',
  computed: {
  users() {
    return this.$store.state.users;
      }
    },
    filters: {
    devise(valeur, devise) {
      const options = {
        style: 'currency',
        currency: devise,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };
      return valeur.toLocaleString('fr-FR', options);
    },
  },
  components: {
    SideBar,
    NavBar,
    VueHtml2pdf
  },
  data() {
    return {
      dateSearch: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10)
        ],
      depensesInfos:{
        date_dep: '',
        somme: '',
        motif: '',
        remis: '',
        recu: '',
        matricule: '',
        type_dep: '',
      },
      form: {
            annee: new Date().getFullYear(),
            mois: '',
            nom: '',
            montant: '',
            observation: '',
          },
      activePrompt2:false,
      popupActivo2:false,
      popupActivo22:false,
      popupActivoCaisse:false,
      popupActivo3:false,
      activePrompt9:false,
      select1:3,
      depensest:[
        {text:'FRAIS_DE_FINANCE',value:0},

      ],
      caisseData: {
        date: '',
        somme: ''
      },
      donneesMois: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
      checkTrue: true,
      checkFalse: false,
      donnees: null,
      donneesCaisse: null,
      totalPrix: null,
      totalSalaire: null,
      totalSalaireok: 0,
      totalDivers: null,
      totalFrais: null,
      totalCarburant: null,
      totalVehicule: null,
      totalVehicules: null,
      totalCamion: null,
      elementChoisi: null,
      donneesZones: null,
      donneesCamion: null,
      donneesChauf: null,
      totalSalaires: 0,
      deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
      like: true,
        value1: '',
        value2: '',
        title: 'Growth this year',
      activeName: 'first',
      chartData: {
        labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
      },
      chartOptions: {
        responsive: true
      },
      deletebon: '',
      
        options: [{
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }],
        type_cont: [{
          value: "20 'OPEN",
          label: "20 'OPEN"
        }, {
          value: "40 'OPEN",
          label: "40 'OPEN"
        }, {
          value: "20 'FLAT",
          label: "20 'FLAT"
        },{
          value: "40 'FLAT",
          label: "40 'FLAT"
        }, {
          value: "20 'DRY",
          label: "20 'DRY"
        }, {
          value: "40 'DRY",
          label: "40 'DRY"
        }, {
          value: "20 'FRIGO",
          label: "20 'FRIGO"
        }, {
          value: "40 'FRIGO",
          label: "40 'FRIGO"
        }],
        armateur: [{
          value: "Evergreen",
          label: "Evergreen"
        }, {
          value: "Maersk",
          label: "Maersk"
        }, {
          value: "Safmarine",
          label: "Safmarine"
        },{
          value: "Cosco",
          label: "Cosco"
        }, {
          value: "One",
          label: "One"
        }, {
          value: "PIL",
          label: "PIL"
        }, {
          value: "Oma",
          label: "Oma"
        }, {
          value: "Medlog",
          label: "Medlog"
        }],
        anneeSelect: new Date().getFullYear(),
        moisSelect: '',
      tableData: [{
          date: '2016-05-03',
          name: 'TRAORE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-02',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Validé'
        }, {
          date: '2016-05-04',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-01',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Annulé'
        }, {
          date: '2016-05-08',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Validé'
        }, {
          date: '2016-05-06',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-07',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }],
    }
  },
  methods: {

    modifBon(list) {
      console.log(list)
      this.form= {
            annee: list.annee,
            mois: list.mois,
            nom: list.nom,
            montant: list.montant,
            observation: list.observation,
            id: list.id_salarie
          },
        this.popupActivo3 = true;
        
      },

      supBon(id) {
      this.deletebon = id;
      this.$vs.dialog({
      color:'danger',
      title: `Suppression`,
      text: 'Êtes vous sûre de vouloir supprimer ?',
      accept:this.onDelete
    })
        
      },

    listeDepenses () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_salarie.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.totalSalaires = response.data.total[0].total_salaire;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
      },

      listeCaisse () {
          this.$vs.loading({
          type:'point',
          })
          axios.get('https://socogetra.net/appi_sococit/albakoos_caisse.php?timestamp=' + new Date().getTime())
          .then((response) => {
            this.donneesCaisse = response.data.caisse;
            this.$vs.loading.close()
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
      },

      listeZones () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesZones = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        generateReport () {
            this.activePrompt9 = true;
            this.$refs.html2Pdf.generatePdf()
        },

        searchDate () {
            this.$vs.loading({
            type:'point',
            })
            axios
                .get('https://socogetra.net/appi_sococit/albakoos_salaire_periode.php?mois=' + this.moisSelect + '&annee=' + this.anneeSelect + '&timestamp=' + new Date().getTime())
                .then(response => (this.donnees = response.data.data, this.totalSalaires = response.data.total[0].total_salaire, this.$vs.loading.close()))

        },

        exportToPDF() {
          html2pdf(document.getElementById("html-to-pdf"), {
            margin: 1,
            filename: "i-was-html.pdf",
          });
        },

        listeChauffeurs () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesChauf = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        Borderau(element) {
          this.elementChoisi = element;
          this.activePrompt9 = true;
          this.generateReport();
        },

        generatePDF () {
          var doc = new jsPDF();
          
          // Récupérer l'élément HTML que vous voulez convertir en PDF
          const element = document.getElementById('html-to-pdf')

          // Convertir l'élément HTML en Canvas
          html2canvas(element).then(canvas => {
            // Ajouter le Canvas au document PDF avec une résolution de 300 DPI
            const imgData = canvas.toDataURL('image/png', 2.7);
            doc.addImage(imgData, 'PNG', 10, 10);
            
            // Sauvegarder le document PDF
            doc.save('example.pdf')
          })
        },

       // generatePDF () {
        //  var doc = new jsPDF();

          // Récupérer l'élément HTML que vous voulez convertir en PDF
        //  const element = document.getElementById('html-to-pdf')

          // Convertir l'élément HTML en Canvas
        //  html2canvas(element).then(canvas => {
            // Ajouter le Canvas au document PDF
         //   const imgData = canvas.toDataURL('image/png')
          //  doc.addImage(imgData, 'PNG', 10, 10)

            // Sauvegarder le document PDF
         //   doc.save('example.pdf')
        //  })
       // },

        listeCamion () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesCamion = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        formatMoney(value) {
          const numberValue = parseFloat(value.replace(/ /g, '').replace(/,/g, '.'));
          if (isNaN(numberValue)) {
            return '';
          }
          return numberValue.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'XOF'
          });
        },

        handleSelectChange(choix) {
          console.log(choix);
          const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
          this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
        },

        onSubmit() {
          console.log(this.form);
              axios.post('https://socogetra.net/appi_sococit/albakoos_salarie.php', this.form)
              .then((response) => {
                  console.log(response);
                  this.listeDepenses();
                  this.activePrompt2 = false;
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'Salaire OK!',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
          },

        onUpdate() {
            axios.put('https://socogetra.net/appi_sococit/albakoos_salarie.php', this.form)
            .then((response) => {
                console.log(response);
                this.$vs.loading.close();
                this.popupActivo3 = false;
                this.form = {
                  annee: '',
                  mois: '',
                  montant: '',
                  nom: '',
                  observation: '',
                };
                this.listeDepenses();
                this.$notify.success({
                title: 'Salaire !',
                message: 'Modifié avec Succès',
                showClose: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.$vs.loading.close()
            });
        },

        onDelete() {
            axios.delete('https://socogetra.net/appi_sococit/albakoos_salarie.php?id='+this.deletebon)
            .then((response) => {
                console.log(response);
                this.$vs.loading.close();
                this.popupActivo2 = false;
                this.form = {
                  annee: '',
                  mois: '',
                  montant: '',
                  nom: '',
                  observation: '',
                };
                this.listeDepenses();
                this.$notify.success({
                title: 'Salaire !',
                message: 'Supprimé',
                showClose: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.$vs.loading.close()
            });
        },


        onSubmitCaisse() {
            axios.post('https://socogetra.net/appi_sococit/albakoos_caisse.php', this.caisseData)
            .then((response) => {
                console.log(response);
                this.$vs.loading.close();
                this.popupActivoCaisse = false;
                this.listeCaisse();
                this.$notify.success({
                title: 'Caisse !',
                message: 'Incrémentation',
                showClose: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.$vs.loading.close()
            });
        },

      clickFn() {
        this.$refs.statistic.suspend(this.stop);
        this.stop = !this.stop;
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      hilarity() {
        this.$notify({
          title: 'TRAVAIL DU JOUR TERMINE',
          message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
          duration: 0,
        });
      },
    },

    mounted() {
      this.listeDepenses();
      this.listeCaisse();
      this.listeCamion();
      this.listeChauffeurs();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-input__inner::placeholder {
    color: #201f1f;
}

@page {
  margin-bottom: 20mm;
}

</style>
