import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../components/Benne_dashboard.vue'
import Login from '../views/AboutView.vue'
import Cloud from '../components/cloud/Principale.vue'
import Cloud_fichier from '../components/cloud/Fichiers.vue'
import Utilisateur from '../components/Utilisateurs.vue'
import Email from '../components/Email.vue'
import Chat from '../components/Chat.vue'
import Excel from '../components/Excel.vue'
import Zones from '../components/Zones.vue'
import Bennes from '../components/Bennes.vue'
import Bon from '../components/Benne_list.vue'
import CamionsChauffeurs from '../components/Camion_chauffeur.vue'
import Camion from '../components/Camion.vue'
import Salaire from '../components/Salaire.vue'
import Comptabilite from '../components/Comptabilite.vue'
import Finance from '../components/Fianance.vue'
import Famille from '../components/Famille.vue'
import Salairez from '../components/Salairez.vue'
import Chauffeur from '../components/Chauffeur.vue'
//import BenneDashboard from '../components/Benne_dashboard.vue'
import BenneStat from '../components/Benne_stat.vue'
import CamionMobile from '../components/Camion_mobile.vue'



import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/email',
    name: 'email',
    component: Email
  },
  {
    path: '/zones',
    name: 'zones',
    component: Zones
  },
  {
    path: '/benne_stat',
    name: 'benne_stat',
    component: BenneStat
  },
  {
    path: '/camion_mobile',
    name: 'camion_mobile',
    component: CamionMobile
  },
  {
    path: '/camion',
    name: 'camion',
    component: Camion
  },
  {
    path: '/salaire',
    name: 'salaire',
    component: Salaire
  },
  {
    path: '/comptabilite',
    name: 'comptabilite',
    component: Comptabilite
  },
  {
    path: '/finance',
    name: 'finance',
    component: Finance
  },
  {
    path: '/famille',
    name: 'famille',
    component: Famille
  },
  {
    path: '/salairez',
    name: 'Salairez',
    component: Salairez
  },
  {
    path: '/chauffeur',
    name: 'chauffeur',
    component: Chauffeur
  },
  {
    path: '/albakoos_dashboard',
    name: 'homes',
    component: HomeView
  },
  {
    path: '/liste_benne',
    name: 'liste_benne',
    component: Bon
  },
  {
    path: '/camion_chauffeur',
    name: 'camion_chauffeur',
    component: CamionsChauffeurs
  },
  {
    path: '/utilisateurs',
    name: 'utilisateurs',
    component: Utilisateur
  },
  {
    path: '/excel',
    name: 'excel',
    component: Excel
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/cloud',
    name: 'cloud',
    component: Cloud
  },
  {
    path: '/cloud_fichier',
    name: 'cloud_fichier',
    component: Cloud_fichier
  },
  {
    path: '/bennes',
    name: 'bennes',
    component: Bennes
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/doc'+process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const authenticated = store.state.users.name.length;
  console.log(to.path);
  console.log(authenticated);
  console.log(from.path);
  next();
    if(to.path !== '/about') {
      if(authenticated > 2) {
        //location.reload();
        next();
      } else {
        next('/about?error=1');
      }
   }

 //   if(to.path == '/about') {
 //     next();
  //  }
    
  
})

export default router
